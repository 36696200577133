import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { HeaderModal } from "../../molecules/HeaderModal";

const useStyles = createUseStyles({
  outerWrapper: {
    maxWidth: 1900,
    margin: "0 auto",
  },
  contentWrapper: {
    maxWidth: 1440,
    margin: "0 auto",
  },
});

interface Props {
  children: any;
  cartCounter: number;
}

const Container: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [visibleModal, setVisibleModal] = useState(false);

  const toggleModal = () => {
    setVisibleModal(!visibleModal);
  };

  return (
    <div>
      <div className={classes.outerWrapper}>
        <Header onClick={toggleModal} cartCounter={props.cartCounter} />
      </div>
      <div className={classes.contentWrapper}>{props.children}</div>
      <div className={classes.outerWrapper}>
        <Footer />
      </div>
      <HeaderModal
        visible={visibleModal}
        onCancel={toggleModal}
        cartCounter={props.cartCounter}
      />
    </div>
  );
};

export { Container };
