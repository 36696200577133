import React from "react";
import { createUseStyles } from "react-jss";
import ReactPlayer from "react-player";
import { Image, Typography } from "shared";

export interface ISingleArticle {
  articleData: any;
}

const useStyles = createUseStyles({
  mainImage: {
    width: "100%",
    height: 498,
    marginBottom: 18,
    backgroundSize: "contain !important",
    "@media(max-width: 768px)": {
      height: 400,
      marginBottom: 12,
    },
    "@media(max-width: 425px)": {
      height: 240,
      marginBottom: 12,
    },
    "@media(max-width: 375px)": {
      height: 162,
      marginBottom: 12,
    },
  },
  imageWrapper: {
    maxWidth: 1152,
    margin: {
      left: "auto",
      right: "auto",
    },
  },
  Player: {
    width: "100%",
    height: "100%",
  },
  videoWrapper: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 50,
    height: 450,
    "@media(max-width: 768px)": {
      height: 300,
      width: "auto",
    },
  },
  articleHeader: {
    fontSize: 24,
    lineHeight: "46px",
    fontWeight: 700,
    "@media(max-width: 768px)": {
      fontSize: 12,
      lineHeight: "17px",
    },
  },
  mainWrapper: {
    width: "100%",
  },
});
const SingleArticle: React.FC<ISingleArticle> = ({ articleData }) => {
  const classes = useStyles();
  return (
    <div className={classes.mainWrapper}>
      <div className={classes.imageWrapper}>
        <Image
          img={articleData.image}
          className={classes.mainImage}
          background="contain"
        />
      </div>
      <Typography className={classes.articleHeader}>
        {articleData.title}
      </Typography>
      {articleData?.video && articleData.video != "" && (
        <div className={classes.videoWrapper}>
          <ReactPlayer
            className={classes.Player}
            width="100%"
            height="100%"
            url={articleData.video}
            autoPlay={true}
          />
        </div>
      )}
      <div
        contentEditable="false"
        dangerouslySetInnerHTML={{ __html: articleData.article }}
      />
    </div>
  );
};

export { SingleArticle };
