import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Typography, firebase } from "shared";
import Cart from "../../../../public/assets/cart.svg";
import CartWhite from "../../../../public/assets/cart_white.svg";

const useStyles = createUseStyles({
  selectMenu: {
    width: 158,
    marginTop: 8,
  },
  menu: {
    display: "flex",
    "& .ant-select-arrow ": {
      marginTop: -7,
    },
  },
  textClass2: {
    fontSize: 14,
    paddingLeft: 4,
  },
  cartMenuRelative: {
    cursor: "pointer",
    position: "relative",
    display: "flex",
    marginRight: 20,
    "@media(max-width: 768px)": { left: "-22px", paddingBottom: 18 },
  },
  cartMenu: {
    cursor: "pointer",
    position: "unset",
    display: "flex",
    marginRight: 20,
    "@media(max-width: 768px)": { left: "-22px", paddingBottom: 18 },
  },
  cartIcon: {
    width: 40,
    height: 36,
    marginRight: 12,
    "@media(max-width: 768px)": {
      width: 27,
      height: 24,
      marginRight: 18,
    },
  },
  countCircle: {
    position: "relative",
    minWidth: 30,
    height: 30,
    borderRadius: "50%",
    background: "#8D1713",
    display: "flex",
    justifyContent: "center",
    top: "-14px",
    left: "50px",
    "@media(max-width: 768px)": {
      minWidth: 22,
      height: 22,
      left: 33,
      top: "-10px",
    },
  },
  counterText: {
    fontSize: 18,
    lineHeight: "27px",
    color: "#f2f2f2",
    "@media(max-width: 768px)": {
      fontSize: 12,
      lineHeight: "18px",
    },
  },
  textClass1: {
    fontSize: 24,
    lineHeight: "36px",
    "@media(max-width: 1145px)": {
      fontSize: 20,
      lineHeight: "30px",
    },
    "@media(max-width: 768px)": {
      fontSize: 12,
      lineHeight: "18px",
      color: "#f2f2f2",
      fontWeight: "bold",
    },
  },
});

interface Props {
  counter: number;
  theme: "dark" | "white";
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const CartMenu: React.FC<Props> = ({ counter, theme, onClick }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();

  const redirect = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        router.push("/shopping-cart");
      } else {
        router.push("/sign-up");
      }
    });
  };

  return (
    <>
      <div className={classes.menu}>
        <div
          className={
            counter !== 0 ? classes.cartMenuRelative : classes.cartMenu
          }
          onClick={(e) => {
            onClick(e);
            redirect();
          }}
        >
          {counter !== 0 ? (
            <div className={classes.countCircle}>
              <Typography className={classes.counterText}>{counter}</Typography>
            </div>
          ) : (
            <></>
          )}
          <img
            className={classes.cartIcon}
            src={theme === "dark" ? CartWhite : Cart}
            alt="Cart"
          />
          <Typography type="primaryLink" className={classes.textClass1}>
            {t("Cart")}
          </Typography>
        </div>
      </div>
    </>
  );
};

export { CartMenu };
