import React from "react";
import { Layout } from "antd";
import { createUseStyles } from "react-jss";
import { LogoFooter, Divider } from "shared";
import { CustomerSupport } from "../../molecules/CustomerSupport";
import { CopyRight } from "../../molecules/CopyRight";
import { FooterLinkGroups as LinkGroups } from "../../molecules/FooterLinkGroups";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import Delivery from "../../../../public/assets/delivery.svg";
import ReturnGuarentee from "../../../../public/assets/returnGuarentee.svg";
import Confirmation from "../../../../public/assets/confirmation.svg";
const useStyle = createUseStyles({
  footerLayoutBackground: {
    "@media(max-width: 768px)": {
      padding: "24px 28px",
    },
    paddingTop: 30,
    paddingRight: 33,
    paddingLeft: 30,
    paddingBottom: 10,
    background: "#1b2e32",
  },
  footerWrapper: {
    display: "grid",
    gridTemplateColumns: "0.9fr 1.4fr 0.7fr 1.5fr",

    "@media(max-width: 1200px)": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr ",
    },
    "@media(max-width: 715px)": {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    width: "100%",
  },
  linkGroup1: {
    "@media(min-width: 769px)": {
      display: "none",
      marginBottom: 0,
    },
    marginBottom: 48,
  },
  linkGroup2: {
    "@media(max-width: 768px)": {
      display: "none",
    },
  },
  logoContactWrapper: {
    "@media(max-width: 768px)": {
      marginBottom: 48,
    },
  },
  logoWrapper1: {
    "@media(max-width: 768px)": {
      display: "none",
    },
    marginBottom: 43,
  },
  logoWrapper2: {
    "@media(min-width: 769px)": {
      display: "none",
    },
    display: "flex",
    justifyContent: "center",
    marginBottom: 48,
  },
  vGapper: {
    marginBottom: 13,
  },
  divider: {
    border: "1px solid #f2f2f2",
    margin: 0,
  },
  paymentCard: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginRight: "3%",

    "& img": {
      marginTop: 20,
      width: 70,
      height: 50,
    },
    "@media(max-width: 1249px)": {
      "& img": {
        marginTop: 20,
        marginBottom: 30,
      },
    },
  },
  features: {
    display: "flex",
    flexDirection: "column",
    "& img": {
      marginTop: 20,
      width: 170,
      height: 140,
    },
    "@media(max-width: 1249px)": {
      marginRight: "5%",
      marginLeft: "5%",
      "& img": {
        marginTop: 20,
        width: 150,
        height: 110,
      },
    },
  },
  imageInfo: {
    display: "flex",
    justifyContent: "space-around",
    marginLeft: "3%",
    marginRight: "3%",

    "@media(max-width: 1349px)": {
      marginLeft: "5%",
      justifyContent: "space-around",
    },
  },
  mobile: {
    display: "flex",
    marginLeft: "5%",
    justifyContent: "space-around",
    marginRight: "3%",
    marginTop: 30,
    "@media(max-width: 430px)": {
      display: "none",
    },
  },
  certificate: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "8%",

    "@media(max-width: 1199px)": {
      alignItems: "center",
    },
    "@media(max-width: 1200px)": {
      marginRight: "16%",
      marginTop: 20,
    },
    "@media(max-width: 768px)": {
      marginTop: 10,
      marginRight: "0%",
    },
    "@media(max-width: 715px)": {
      display: "none",
    },
    "@media(max-width: 430px)": {
      display: "flex",
      marginTop: 20,
    },
  },
  certificate1: {
    display: "none",

    "@media(max-width: 715px)": {
      display: "flex",
      marginRight: "8%",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  ey: {
    width: 180,
    height: 110,
    marginTop: 20,

    "@media(max-width: 820px)": {
      marginTop: 20,

      marginBottom: 10,
      maxWidth: "100%",
    },
    "@media(max-width: 1441px)": {
      marginTop: 20,
      width: 200,
      marginBottom: 10,
      maxWidth: "100%",
    },
  },

  stripe: {
    maxWidth: 210,
    marginTop: 20,
    height: 55,
    "@media(max-width: 1249px)": {
      "& img": {
        marginTop: 20,
        width: 160,
        height: 95,
      },
    },
    "@media(max-width: 520px)": {
      marginBottom: 10,
    },
  },
  contact: {
    "@media(max-width: 768px)": {
      display: "none",
    },
  },
  contact1: {
    display: "none",
    "@media(max-width: 768px)": {
      display: "block",
      margin: "0 auto",
    },
    "@media(max-width: 715px)": {
      display: "none",
    },
    "@media(max-width: 430px)": {
      display: "block",
      marginTop: 20,
    },
  },
  contact2: {
    display: "none",

    "@media(max-width: 715px)": {
      display: "block",
    },
  },
  headline: {
    color: "#fff",

    padding: 2,
    fontSize: 16,
    textAlign: "center",
    "@media(max-width: 1260px)": {
      fontSize: 15,
    },
  },
  buttomText: {
    textAlign: "right",
    color: "#fff",
    fontSize: 20,
    "@media(max-width: 768px)": {
      marginTop: 20,
      fontSize: 15,
    },
  },
  headingAlignment: {
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
    "@media(max-width: 768px)": {
      marginBottom: 12,
    },
  },
  badge: {
    "@media(min-width: 769px)": {
      marginRight: 20,
      width: 50,
      height: 66,
    },
    position: "relative",
    marginRight: 12,
    width: 18,
    height: 24,
  },
  featureBox: {
    maxWidth: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    marginTop: 20,
    padding: "3px 6px",
    borderRadius: 6,

    "& img": {
      width: 70,
      height: 45,
      marginTop: 0,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: "600",
    paddingBottom: 4,
    color: "#fff",
  },
  subtitle: {
    color: "#fff",
    fontSize: 12,
    fontWeight: "400",
  },
  partner: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginTop: 20,
    "& img": {
      background: "#fff",
      marginTop: 10,
      maxWidth: 200,
      padding: 10,
      "@media(max-width: 400px)": {
        maxWidth: 250,
        padding: 10,
      },
    },

    // display: "flex",
    // alignItems: "center",
  },
  kwa: {
    background: "#fff",
    padding: 8,
    marginTop: 10,
  },
  mobileKwa: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    "& img": {
      maxWidth: 250,
      background: "#fff",
      "@media(max-width: 400px)": {
        maxWidth: 200,
      },
    },
  },
});

const Footer = () => {
  const { Footer } = Layout;
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Footer className={classes.footerLayoutBackground}>
        <div className={classes.footerWrapper}>
          <div className={classes.logoContactWrapper}>
            <div>
              <div className={classes.logoWrapper1}>
                <LogoFooter size="normal" />
              </div>
              <div className={classes.logoWrapper2}>
                <LogoFooter size="small" />
              </div>
            </div>
            <div className={classes.linkGroup1}>
              <LinkGroups />
            </div>
            <div className={classes.contact}>
              <CustomerSupport />
            </div>
          </div>
          <div className={classes.imageInfo}>
            <div className={classes.paymentCard}>
              <strong className={classes.headline}>支払い方法</strong>

              <img alt="master card" src="/assets/payment/mcard.jpg" />
              <img alt="visa" src="/assets/payment/visa1.png" />
              <img alt="american express" src="/assets/payment/amex.png" />
              <img alt="bank" src="/assets/payment/bank.png" />
            </div>
            <div className={classes.features}>
              <strong className={classes.headline}>安心＆便利なお買い物</strong>
              <div className={classes.featureBox}>
                <img src={Delivery} alt="fast-delivery" />
                <strong className={classes.title}> {t("Free Shipping")}</strong>
                <strong className={classes.subtitle}>
                  {t("Free and speedy shipping with careful packing")}
                </strong>
              </div>
              <div className={classes.featureBox}>
                <img src={ReturnGuarentee} alt="return guarentee" />
                <strong className={classes.title}>
                  {" "}
                  {t("Returns Guarantee")}
                </strong>
                <strong className={classes.subtitle}>
                  {t(
                    "Returns can be made within 7 days of receiving the package"
                  )}
                </strong>
              </div>
              <div className={classes.featureBox}>
                <img src={Confirmation} />
                <strong className={classes.title}>
                  {" "}
                  {t("Genuine Product")}
                </strong>
                <strong className={classes.subtitle}>
                  {t(
                    "You can actually check the carpet before purchasing. Please contact us for details"
                  )}
                </strong>
              </div>
            </div>
          </div>

          <div className={classes.certificate}>
            <strong className={classes.headline}>セキュリティ</strong>
            <Link href="https://workspace.google.co.jp/intl/ja/security/?secure-by-design_activeEl=data-centers">
              <a target="_blank">
                <img className={classes.ey} src="/assets/payment/ey.png" />
              </a>
            </Link>
            <Link href="https://stripe.com/jp/payments">
              <a target="_blank">
                <img
                  className={classes.stripe}
                  src="/assets/payment/stri.png"
                />
              </a>
            </Link>
            <div>
              <div className={classes.partner}>
                <strong className={classes.headline}>{t("Membership")}</strong>
                <Link href="https://www.kawagoe.or.jp/">
                  <a target="_blank">
                    <img src="/assets/logokwa.png" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.linkGroup2}>
            <LinkGroups />
          </div>
          <div className={classes.contact1}>
            <CustomerSupport />
          </div>

          {/* mobile use case */}
          <div className={classes.mobile}>
            <div className={classes.certificate1}>
              <strong className={classes.headline}>セキュリティ</strong>
              <Link href="https://workspace.google.co.jp/intl/ja/security/?secure-by-design_activeEl=data-centers">
                <a target="_blank">
                  <img className={classes.ey} src="/assets/payment/ey.png" />
                </a>
              </Link>
              <Link href="https://stripe.com/jp/payments">
                <a target="_blank">
                  <img
                    className={classes.stripe}
                    src="/assets/payment/stri.png"
                  />
                </a>
              </Link>
              <div className={classes.mobileKwa}>
                <strong className={classes.headline}>{t("Membership")}</strong>
                <Link href="https://www.kawagoe.or.jp/">
                  <a target="_blank">
                    <img className={classes.kwa} src="/assets/logokwa.png" />
                  </a>
                </Link>
              </div>
            </div>
            <div className={classes.contact2}>
              <CustomerSupport />
            </div>
          </div>
        </div>
        <div className={classes.buttomText}>
          {t(
            "決済システムStripe は、業界の最も厳しい標準に準拠しており、世界各国の規制に関するライセンスを取得しています"
          )}
        </div>
        <Divider className={classes.divider} />
        <CopyRight />
      </Footer>
    </React.Fragment>
  );
};

export { Footer };
