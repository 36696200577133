import React, { useContext } from "react";
import { Modal, message } from "antd";
import { createUseStyles } from "react-jss";
import { Divider, Typography, firebase } from "shared";
import { StatusBar } from "../StatusBar";
import { ContactCall } from "../ContactCall";
import { ContactEmail } from "../ContactEmail";
import { HeaderMenu } from "../HeaderMenu";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { CartMenu } from "../CartMenu";
import signin from "../../../../public/assets/signin.svg";
import userWhite from "../../../../public/assets/user_white.svg";
import signOut from "../../../../public/assets/signOut_white.svg";
import { AuthContext } from "../../../pages/_app";

const useStyles = createUseStyles({
  mainWrapper: {
    "& .ant-modal": {
      width: 313,
      height: "100%",
      paddingBottom: 0,
      top: 0,
      margin: 0,
      "& >.ant-modal-content": {
        width: 313,
        height: "100%",
        padding: 0,
        margin: 0,
        background: "none",
        "& >.ant-modal-header": {
          display: "none",
        },
        "& >.ant-modal-footer": {
          display: "none",
        },
      },
    },
    "& .ant-modal-body": {
      padding: 0,
      position: "absolute",
      top: 0,
    },
    "& .ant-modal-close": {
      "@media(min-width: 644px) and (max-width: 759px)": { left: 600 },
      "@media(min-width: 550px) and (max-width: 643px)": { left: 500 },
      "@media(min-width: 501px) and (max-width: 549px)": { left: 450 },
      "@media(min-width: 425px) and (max-width: 500px)": { left: 380 },
      "@media(min-width: 375px) and (max-width: 424px)": { left: 330 },
      "@media(max-width: 374px)": {
        left: 265,
        top: 35,
      },
      top: 12,
      left: 720,
      position: "relative",
      background: "#fff",
      width: 32,
      height: 32,
      borderRadius: "50%",
      padding: 10,
      "& >.ant-modal-close-x": {
        width: 12,
        height: 12,
        fontSize: 12,
        lineHeight: 12,
        "& >.anticon": {
          position: "absolute",
          left: 10,
        },
      },
    },
    "& .ant-divider": {
      borderTop: "1px solid #465558",
    },
  },
  divWrapper: {
    background: "#1b2e32",
    width: 313,
    height: "100vh",
    "@media screen and (max-height: 595px)": {
      height: "fit-content",
    },
  },
  divider: {
    margin: 0,
  },
  contentWrapper: {
    paddingTop: 22,
    paddingBottom: 24,
    paddingLeft: 28,
    paddingRight: 28,
  },
  userWrapper: {
    marginBottom: 48,
    display: "flex",
    cursor: "pointer",
  },
  userName: {
    marginLeft: 12,
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "17px",
    color: "#f2f2f2",
  },
  signInTitle: {
    marginLeft: 12,
    fontSize: "12px",
    lineHeight: "17px",
    color: "#f2f2f2",
  },
  callWrap: {
    marginBottom: 22,
  },
  textClass1: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#f2f2f2",
    marginTop: 5,
  },
  bottomPosition: {
    bottom: 24,
    position: "absolute",
    "@media screen and (max-height: 595px)": {
      position: "unset",
    },
  },
  cartLink: {
    marginRight: 0,
    marginBottom: 32,
    borderBottom: "1px solid #465558",
  },
  signOut: {
    display: "flex",
    marginBottom: 109,
    cursor: "pointer",
  },
});

interface Props {
  visible?: boolean;
  onCancel?: any;
  cartCounter?: number;
}

const HeaderModal: React.FC<Props> = ({
  visible,
  onCancel,
  cartCounter,
}: Props) => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  return (
    <Modal
      wrapClassName={classes.mainWrapper}
      visible={visible}
      onCancel={onCancel}
    >
      <div className={classes.divWrapper}>
        <StatusBar />
        <Divider className={classes.divider} />
        <div className={classes.contentWrapper}>
          <div>
            {!firebase.auth().currentUser ? (
              <div
                className={classes.userWrapper}
                onClick={() => {
                  router.push("/sign-up");
                  onCancel();
                }}
              >
                <img src={signin} />
                <Typography className={classes.signInTitle}>
                  {t("Login")}
                </Typography>
              </div>
            ) : (
              <div
                className={classes.userWrapper}
                onClick={() => {
                  router.push("/");
                  onCancel();
                }}
              >
                <img src={userWhite} />
                <div>
                  <Typography className={classes.userName}>
                    {userData.firstName} {userData.lastName}
                  </Typography>
                  <Typography className={classes.signInTitle}>
                    {userData.email}
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <HeaderMenu onClick={onCancel} />
          <div>
            <div className={classes.cartLink}>
              <CartMenu theme="dark" counter={cartCounter} onClick={onCancel} />
            </div>
            {firebase.auth().currentUser ? (
              firebase.auth().currentUser.emailVerified ? (
                <div
                  className={classes.signOut}
                  onClick={() => {
                    firebase.auth().signOut();
                    message.success(t("Signed out sucessfully."));
                    onCancel();
                  }}
                >
                  <img src={signOut} />
                  <Typography className={classes.userName}>
                    {t("Sign Out")}
                  </Typography>
                </div>
              ) : (
                <></>
              )
            ) : (
              <> </>
            )}
          </div>

          <div className={classes.bottomPosition}>
            <div className={classes.callWrap}>
              <ContactCall
                theme="dark"
                header={true}
                phoneNumber={"080-3578-9185"}
              />
              <Typography className={classes.textClass1}>
                {t("10:00-18:00 Sunday & Holidays closed")}
              </Typography>
            </div>
            <div className={classes.callWrap}>
              <ContactCall
                theme="dark"
                header={true}
                phoneNumber={"049-293-4004"}
              />
              <Typography className={classes.textClass1}>
                {t("In hurry?-Support 24 hours")}
              </Typography>
            </div>

            <div
              className="line-it-button"
              data-lang="ja"
              data-size="large"
              data-type="friend"
              data-lineid="@700jkjfc"
              style={{ display: "none" }}
            ></div>

            <ContactEmail theme="dark" extraText={false} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { HeaderModal };
