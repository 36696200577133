import React from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "shared/src/components/atoms/Typography";
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  wrapper: {
    "@media(max-width: 768px)": {
      paddingTop: 12,
      paddingLeft: 28,
      paddingRight: 28,
      paddingBottom: 19,
      margin: 0,
    },
    paddingTop: 8,
    paddingLeft: 11,
    paddingBottom: 1,
    margin: "0px 1px",
    background: "#1B2E32",
  },
  textClass1: {
    "@media(min-width: 769px)": {
      fontSize: "14px",
      lineHeight: "21px",
    },
    fontSize: "12px",
    lineHeight: "18px",
  },
});

const StatusBar: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.textClass1} color="#F2F2F2">
        {t(
          "High quality Persian carpets shipping from Japan, Quality assurance"
        )}
      </Typography>
    </div>
  );
};

export { StatusBar };
