import React from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "shared";
import Caller from "shared/src/components/assets/caller.svg";
import CallerDark from "shared/src/components/assets/caller_dark.svg";
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  caller: {
    cursor: "pointer",
    width: 20,
    height: 18,
    marginRight: 5,
    marginLeft: 10,
    "@media(min-width: 769px)": {
      marginTop: ({ theme }: Props) => {
        return theme === "dark" ? 10 : 5;
      },
      marginRight: ({ theme }: Props) => {
        return theme === "dark" ? 15 : 5;
      },
      width: ({ theme }: Props) => {
        return theme === "dark" ? 39 : 27.43;
      },
      height: ({ theme }: Props) => {
        return theme === "dark" ? 34.41 : 24;
      },
    },
  },
  timeContent: {
    float: "left",
    marginTop: ({ theme }: Props) => {
      return theme === "dark" ? 5 : 3;
    },
    display: ({ header }: Props) => {
      return header ? "none" : "flex";
    },
    "@media(max-width: 768px)": {
      float: "left",
    },
  },
  wrapper: {
    width: "fit-content",
  },
  iconGapper: {
    display: "flex",
    alignItems: "center",
  },
  textClass1: {
    "@media(min-width: 769px)": {
      fontSize: ({ theme }: Props) => {
        return theme === "dark" ? "36px" : "24px";
      },
      lineHeight: ({ theme }: Props) => {
        return theme === "dark" ? "52px" : "35px";
      },
    },
    fontSize: "12px",
    lineHeight: "18px",
    color: ({ theme }: Props) => {
      return theme === "dark" ? "#f2f2f2" : "#1b2e32";
    },
  },
  textClass2: {
    "@media(min-width: 769px)": {
      fontSize: ({ theme }: Props) => {
        return theme === "dark" ? "16px" : "15px";
      },
      lineHeight: "22px",
    },
    fontSize: "12px",
    lineHeight: "18px",
    color: ({ theme }: Props) => {
      return theme === "dark" ? "#f2f2f2" : "#82999b";
    },
  },
  toggleContent1: {
    "@media(max-width: 768px)": {
      display: "none",
    },
  },
  toggleContent2: {
    "@media(min-width: 769px)": {
      display: "none",
    },
  },
});

interface Props {
  theme: "white" | "dark";
  header: boolean;
  extraText?: string;
  phone?: boolean;
  phoneNumber?: string;
}

const ContactCall: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.iconGapper}>
        <img
          className={classes.caller}
          src={props.theme === "dark" ? CallerDark : Caller}
          alt="Phone"
        />
        <div>
          <Typography className={classes.textClass1}>
            {t(props.phoneNumber)}
          </Typography>
        </div>
      </div>

      <div className={classes.timeContent}>
        <div className={classes.toggleContent1}>
          <Typography className={classes.textClass2}>
            {props.extraText}
          </Typography>
        </div>
        <div className={classes.toggleContent2}>
          <Typography className={classes.textClass2}>
            {t("Business Hours: 10:00-18:00")}
          </Typography>
          <Typography className={classes.textClass2}>
            {t("Sunday & Holidays closed")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { ContactCall };
