import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { createUseStyles } from "react-jss";
import { firebase, removeToken } from "shared";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import user from "../../../../public/assets/user.svg";
import history from "../../../../public/assets/history.svg";
import signOut from "../../../../public/assets/signout.svg";
import { AuthContext } from "../../../pages/_app";
import { destroyCookie } from "nookies";

const useStyles = createUseStyles({
  userWrapper: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    borderRadius: 2,
  },
  dropDownMenu: {
    width: 158,
    marginTop: 8,
  },
  textClass2: {
    fontSize: 14,
    paddingLeft: 6,
    overflow: "hidden",
    overflowWrap: "normal",
    textOverflow: "ellipsis",
    width: 96,
  },
  dropDownButton: {
    display: "flex",
    alignItems: "center",
    padding: "4px 11px 4px 14px",
  },
  dropDownArrow: {
    paddingTop: 4,
  },
});

const UserMenu: React.FC = () => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  const handlePurchaseHistory = () => {
    router.push("/order-history", "/order-history", { shallow: true });
  };

  const handleSignOut = () => {
    firebase.auth().signOut();
    router.push("/sign-up");
    removeToken();
    destroyCookie({}, "accessToken", {
      path: "/",
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={handlePurchaseHistory}>
        <img src={history} />
        <span className={classes.textClass2}>{t("Purchase History")}</span>
      </Menu.Item>
      <Menu.Item onClick={handleSignOut}>
        <img src={signOut} />
        <span className={classes.textClass2}>{t("Sign Out")}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={classes.userWrapper}>
      <Dropdown
        className={classes.dropDownMenu}
        overlay={menu}
        placement="bottomRight"
        arrow={true}
      >
        {router.pathname === "/order-history" ? (
          <Button className={classes.dropDownButton}>
            <img src={history} />
            <span className={classes.textClass2}>{t("Purchase History")}</span>
            <DownOutlined className={classes.dropDownArrow} />
          </Button>
        ) : (
          <Button className={classes.dropDownButton}>
            <img src={user} />
            <span className={classes.textClass2}>{userData.firstName}様</span>
            <DownOutlined className={classes.dropDownArrow} />
          </Button>
        )}
      </Dropdown>
    </div>
  );
};

export { UserMenu };
