import React from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "shared";
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  menuWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "@media(max-width: 768px)": {
      flexDirection: "column",
    },
  },
  itemWrapper: {
    "@media(max-width: 768px)": {
      marginRight: 0,
      marginBottom: 32,
      borderBottom: "1px solid #465558",
    },
    "@media(max-width: 825px)": {
      marginRight: 15,
    },
    "@media(min-width: 826px) and (max-width: 1024px)": {
      marginRight: 30,
    },
    marginRight: 40,
  },
  lastItem: {
    marginRight: 0,
    marginBottom: 0,
  },
  textClass1: {
    "@media(max-width: 768px)": {
      fontSize: "12px",
      lineHeight: "18px",
      marginBottom: 12,
      color: "#f2f2f2",
      fontWeight: "bold",
    },
    "@media(min-width: 769px)": {
      fontSize: "20px",
      lineHeight: "30px",
    },
    "@media(min-width: 1145px)": {
      fontSize: "20px",
      lineHeight: "30px",
    },
    color: "#1b2e32",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

interface Props {
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}
const HeaderMenu: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.menuWrapper}>
      <div className={classes.itemWrapper} onClick={onClick}>
        <Typography
          type="primaryLink"
          className={classes.textClass1}
          href={"/customer-voice"}
        >
          {t("Feedback")}
        </Typography>
      </div>
      <div className={classes.itemWrapper} onClick={onClick}>
        <Typography
          type="primaryLink"
          className={classes.textClass1}
          href={"/request"}
        >
          {t("Request")}
        </Typography>
      </div>
      <div className={classes.itemWrapper} onClick={onClick}>
        <Typography
          type="primaryLink"
          className={classes.textClass1}
          href={"/mirmehdi"}
        >
          {t("Workshop Introduction")}
        </Typography>
      </div>
      <div className={classes.itemWrapper} onClick={onClick}>
        <Typography
          type="primaryLink"
          className={classes.textClass1}
          href={"/carpet-articles"}
        >
          {t("Carpet Magazine")}
        </Typography>
      </div>
      <div className={classes.itemWrapper} onClick={onClick}>
        <Typography
          type="primaryLink"
          className={classes.textClass1}
          href={"/shopping-guide"}
        >
          {t("Shopping Guide")}
        </Typography>
      </div>
    </div>
  );
};

export { HeaderMenu };
