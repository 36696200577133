import React from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "shared";

const useStyles = createUseStyles({
  copyRight: {
    marginTop: 12,
    display: "flex",
    "@media(max-width: 768px)": {
      justifyContent: "center",
    },
  },
  textClass1: {
    "@media(max-width: 768px)": {
      fontSize: "16px",
      lineHeight: "23px",
    },
    fontSize: "18px",
    lineHeight: "27px",
    color: "#f2f2f2",
  },
});

const CopyRight: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.copyRight}>
      <Typography className={classes.textClass1}>© 2020 GOLESTAN</Typography>
    </div>
  );
};

export { CopyRight };
