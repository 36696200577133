import React from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { createUseStyles } from "react-jss";
import signIn from "../../../../public/assets/signin_black.svg";
import { Button } from "shared";

const useStyles = createUseStyles({
  userWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    width: 158,
    padding: "14px 45px 15px 30px",
    backgroundColor: "#FAFAFA",
    display: "flex",
    alignItems: "center",
    paddingTop: 12,
    borderRadius: 2,
    cursor: "pointer",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
    "& span": {
      fontSize: 14,
      lineHeight: "20px",
      color: "#1b2e32",
    },
    "&:hover": {
      backgroundColor: "#FAFAFA",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
    },
  },
  signinIcon: {
    marginRight: 4,
    width: 23,
    height: 20,
  },
});

const Login: React.FC = () => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();

  const handleClick = () => {
    router.push("/sign-up");
  };

  return (
    <div className={classes.userWrapper}>
      <Button className={classes.button} onClick={handleClick}>
        <img src={signIn} className={classes.signinIcon} />
        <span>{t("Login")}</span>
      </Button>
    </div>
  );
};

export { Login };
