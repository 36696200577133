import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/node";
import "antd/dist/antd.css";
import { setCookie } from "nookies";
import Router from "next/router";
import { firebase, setToken, API } from "shared";
import { Container } from "./../components";
import { CartContext } from "../cart_context";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Head from "next/head";

type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  authenticated: boolean;
  setUser: any;
  decodedToken: any;
  setDecodedToken: any;
  setLoading: any;
  userData: UserData;
};

interface UserData {
  buildingMansionName?: string;
  city?: string;
  createdAt?: string;
  email: string;
  firstName: string;
  firstNameKatakana: string;
  lastName: string;
  lastNameKatakana: string;
  phoneNumber?: string;
  postalCode?: string;
  prefecture?: string;
  streetName?: string;
  updatedAt?: string;
  userId: string;
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `app-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

if (typeof window !== "undefined" && process.env.NODE_ENV === "production") {
  const logEvent = (url: string) => {
    firebase.analytics().setCurrentScreen(url);
    firebase.analytics().logEvent("screen_view", {
      screen_name: url,
      app_name: "Golestan",
    });
  };

  Router.events.on("routeChangeComplete", (url) => {
    logEvent(url);
  });

  logEvent(window.location.pathname);
}

export const AuthContext = React.createContext<Partial<ContextProps>>({});

const MyApp = ({ Component, pageProps, router }) => {
  const [cartCounter, setCartCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null as firebase.User | null);
  const [userData, setUserData] = useState({} as UserData);

  const git = `https://golestan.jp/assets/seo.png`;

  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      window.scrollTo(0, 0);
    });
  }, [router.events]);

  useEffect(() => {
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }

    initialLoad();
    const cartItems = localStorage.getItem("cart");
    if (cartItems) {
      const carts = JSON.parse(cartItems);
      setCartCounter(carts.filter((i: number) => Number.isInteger(i)).length);
    } else {
      setCartCounter(0);
    }
  }, []);

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        setUser(user);
        if (user !== null) {
          setUser(user);
          const idToken = await user!.getIdToken();
          setCookie(null, "accessToken", idToken, {
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
          });
          setToken(idToken);

          const userDetails: any = await API.get("/users/info");
          if (userDetails && userDetails.data) {
            const userResponse = userDetails.data;
            setUserData({
              firstName: userResponse.first_name || "",
              buildingMansionName: userResponse.building_mansion_name || "",
              city: userResponse.city || "",
              createdAt: userResponse.created_at || "",
              email: userResponse.email || "",
              firstNameKatakana: userResponse.first_name_katakana || "-",
              lastName: userResponse.last_name || "",
              lastNameKatakana: userResponse.last_name_katakana || "-",
              phoneNumber: userResponse.phone_number || "",
              postalCode: userResponse.postal_code || "",
              prefecture: userResponse.prefecture || "",
              streetName: userResponse.street_name || "",
              updatedAt: userResponse.updated_at || "",
              userId: userResponse.user_id || "",
            });
          }
        }
      } catch (e) {
        //console.log(e)
      }
      setLoading(false);
    });
  };

  return (
    <>
      <Head>
        <title>
          Golestan Japan ＜公式サイト＞ 国内最大 シルク絨毯
          高級ペルシャ絨毯専門サイト
        </title>
        <meta
          name="description"
          content="ペルシャ絨毯の中でも世界最高峰の品質とデザインであり見て美しく使うと心地良いシルク絨毯には. シルクロードの文化や歴史そして精神までも紡がれています。国内発送-無料配送-返品保証-最安値"
        />
        <meta property="og:url" content="http://golestan.jp/" />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="2747726002141483" />
        <meta
          property="og:title"
          content="Golestan Japan＜公式サイト＞ | 国内最大 シルク絨毯 | 高級ペルシャ絨毯専門サイト"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          property="og:description"
          content="ペルシャ絨毯の中でも世界最高峰の品質とデザインであり見て美しく使うと心地良いシルク絨毯には. シルクロードの文化や歴史そして精神までも紡がれています。国内発送-無料配送-返品保証-最安値"
        />
        <meta property="og:site_name" content="Golestan" />
        <meta property="og:image" content={git} />
      </Head>
      <AuthContext.Provider
        value={{
          loading,
          user,
          authenticated: user !== null,
          userData,
          setUser,
          setLoading,
        }}
      >
        <Container cartCounter={cartCounter}>
          <CartContext>
            <Component setCartCounter={setCartCounter} {...pageProps} />
          </CartContext>
        </Container>
      </AuthContext.Provider>
    </>
  );
};

export default MyApp;
