import React from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "shared";
import Mailbox from "shared/src/components/assets/mailbox.svg";
import MailboxDark from "shared/src/components/assets/mailbox_dark.svg";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
const useStyles = createUseStyles({
  mailbox: {
    width: 18,
    height: 18,
    "@media(min-width: 769px)": {
      marginRight: ({ theme }: Props) => {
        return theme === "dark" ? 15 : 5;
      },
      width: ({ theme }: Props) => {
        return theme === "dark" ? 39 : 24;
      },
      height: ({ theme }: Props) => {
        return theme === "dark" ? 39 : 24;
      },
      marginTop: ({ theme }: Props) => {
        return theme === "dark" ? 0 : 3;
      },
    },
    marginTop: 0,
    marginRight: 5,
    cursor: "pointer",
  },
  iconGapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "@media(max-width: 1100px)": {
      marginLeft: 10,
    },
  },
  textClass1: {
    "@media(max-width: 768px)": {
      fontSize: "12px",
      lineHeight: "18px",
    },
    fontSize: "24px",
    lineHeight: "36px",
    color: ({ theme }: Props) => {
      return theme === "dark" ? "#f2f2f2" : "#1b2e32";
    },
  },
});

interface Props {
  theme?: "white" | "dark";
  extraText?: boolean;
}

const ContactEmail: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const router = useRouter();
  const contactHandler = () => {
    router.push("/contact").then(() => window.scrollTo(0, 0));
  };
  return (
    <div className={classes.iconGapper} onClick={contactHandler}>
      <img
        className={classes.mailbox}
        src={props.theme === "dark" ? MailboxDark : Mailbox}
        alt="Email"
      />
      <Typography className={classes.textClass1}>
        {props.extraText ? t("Contact Us via E-mail") : t("E-mail")}
      </Typography>
    </div>
  );
};

export { ContactEmail };
